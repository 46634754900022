import React from 'react';
import { ReactComponent as NotFoundImage } from './not-found.svg';

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <NotFoundImage className='w-1/2 -mt-24 mb-0' alt='Content Not Found' />
      <h1 className='text-white lowercase text-2xl font-normal -mt-24 mb-20'>What you're looking for isn't there!</h1>
    </div>
  );
};

export default NotFound;
