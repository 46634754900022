import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Local Components
import Header from './components/header/Header';
import Home from './pages/home/Home';
import Why from './pages/why/Why';
import NotFound from './pages/notfound/NotFound';
import Stage from './pages/stage/Stage';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import Events from './pages/events/Events';
import Event from './pages/events/Event';
import Contact from './pages/contact/Contact';
import Demo from './pages/demo/Demo';

// Hooks
import useApiUrl from './hooks/useApiUrl';
import useAuth from './hooks/useAuth';

// Contexts
import ApiUrlContext from './contexts/apiurl';
import JwtTokenContext from './contexts/JwtToken';
import currentUserContext from './contexts/currentUser';

import './App.css';

function App() {
  // API Url to use based on the current environment
  const apiUrl = useApiUrl();

  const {
    login,
    logout,
    signup,
    isAuthenticated,
    currentUser,
    jwtToken,
  } = useAuth(apiUrl);

  // @TODO: Refactor protected routes logic
  return (
    <>
      {
        /* Let's make sure that the needed info for the app is loaded before we start
         * using this info and crash the app when it's not found.
         */
      }
      {!apiUrl && <h1 className='text-white'>Be right with you in a moment!</h1>}

      {apiUrl && <ApiUrlContext.Provider value={apiUrl}>
        <Header isAuthenticated={isAuthenticated} currentUser={currentUser} logout={logout} />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
        </Switch>
        <div className="container mx-auto">
          <currentUserContext.Provider value={currentUser}>
            <JwtTokenContext.Provider value={jwtToken}>
              <Switch>
                <Route exact path='/'>
                  <></>
                </Route>

                <Route path='/dashboard'>
                  <Events />
                </Route>

                <Route path="/why">
                  <Why />
                </Route>

                <Route path="/contact">
                  <Contact />
                </Route>

                <Route path="/demo">
                  <Demo />
                </Route>

                <Route path="/login">
                  <Login login={login} />
                </Route>

                <Route path="/signup">
                  <Signup signup={signup} />
                </Route>

                {isAuthenticated() ? <Route path="/events/:eventID/stage"
                    children={<Stage />} /> : <Login login={login} />}

                <Route path="/events/:id" children={<Event />} />>

                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </JwtTokenContext.Provider>
          </currentUserContext.Provider>
        </div>
      </ApiUrlContext.Provider>}
    </>
  );
};

// @TODO: Add support for private routes
// https://reactrouter.com/web/example/auth-workflow

export default App;
