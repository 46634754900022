import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Countdown = (props) => {
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [timer, setTimer] = useState();

  const targetDate = moment(props.targetDate);

  useEffect(() => {
    setTimer(setInterval(() => {
      const now = moment();
      const countdown = moment(targetDate - now);

      if (targetDate >= now) {
        setDays(countdown.format('D'));
        setHours(countdown.format('HH'));
        setMinutes(countdown.format('mm'));
        setSeconds(countdown.format('ss'));
      }
    }, 1000));

    return () => clearInterval(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='text-white text-xl font-bold flex align-center justify-center'>
      {days && <div className='item'>
        {days}
        <span className='font-semibold uppercase px-2'>days,</span>
      </div>}
      {hours && <div className='item'>
        {hours}
        <span className='font-semibold uppercase px-2'>hours,</span>
      </div>}
      {minutes && <div className='item'>
        {minutes}
        <span className='font-semibold uppercase px-2'>minutes,</span>
      </div>}
      {seconds && <div className='item'>
        {seconds}
        <span className='font-semibold uppercase px-2'>seconds</span>
      </div>}
    </div>
  );
};

export default Countdown;
