import React, { useRef, useEffect } from 'react';

const MediaPlayer = (props) => {
  const container = useRef(null);
  const { videoTrack, audioTrack } = props;

  useEffect(() => {
    // Nothing to do if there is no target HTML element
    if (!container.current) return;

    videoTrack && videoTrack.play(container.current);

    return () => {
      videoTrack && videoTrack.stop();
    };
  }, [container, videoTrack]);

  useEffect(() => {
    audioTrack && audioTrack.play();

    return () => {
      audioTrack && audioTrack.stop();
    };
  }, [audioTrack]);

  return (
    <div ref={container}
         className='video-player min-w-full min-h-full'
         style={{ width: '640px', height: '480px' }}>
    </div>
  );
};

export default MediaPlayer;
