import { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};


/*
 * @returns {Array} login, logout, signup, update, isAuthenticated, currentUser, jwtToken
*/
const useAuth = (apiUrl) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [jwtToken, setJwtToken] = useState('');

  /*
   * @param {string} email
   * @param {string} password
   * @returns {Array} result
  */
  const login = async (email, password) => {
    // @TODO: Fail if email and password fields are not valid

    try {
      const response = await axios.post(`${apiUrl}/login`, {
        user: { email, password }
      });

      setJwtToken(response.headers.authorization);

      setCurrentUser(response.data);

      return [true, response.data];
    } catch (e) {
      if (e.response) {
        const result = {
          status: e.response.status,
          message: e.response.data.error
        };

        console.log('Login Failure:', result);

        return [false, result];
      } else if (e.request) {
        console.warn('Request Failure:', e.request);

        return [false, e.request];
      } else {
        console.error('Error', e.message);

        return [false, e];
      }
    }
  };

  const logout = () => {
    // @TODO: Logout from the back-end

    localStorage.setItem('jwt-token', '');
    setJwtToken('');
    localStorage.setItem('user-profile', '');
    setCurrentUser({});
  };

  /*
   * @param {string} email
   * @param {string} password
   * @param {string} firstName
   * @param {string} lastName
  */
  const signup = async (email, password, firstName, lastName) => {
    // @TODO: Fail if email and password fields are not valid

    try {
      const response = await axios.post(`${apiUrl}/signup`, {
        user: { email, password }
      });

      // Auto login user after creating their account
      await login(email, password);

      return [true, response];
    } catch (e) {
      if (e.response) {
        const result = {
          status: e.response.status,
          messages: e.response.data
        };

        console.log('Signup Failure:', result);

        return [false, result];
      } else if (e.request) {
        console.warn('Signup Failure:', e.request);

        return [false, e.request];
      } else {
        console.error('Signup', e.message);

        return [false, e];
      }
    }
  };

  /*
   * Update user account and profile details
   * @todo Implement this function
   * @param {object} profile
   * @param {string} profile.email
   * @param {string} profile.password
   * @param {string} profile.firstName
   * @param {string} profile.lastName
   * @param {string} profile.bio
   * @param {*} profile.photo
   * @param {?string} profile.phone
   * @param {?string} profile.title
   * @param {?string} profile.company
   * @param {?string} profile.linkedInURL
   * @param {?string} profile.twitterURL
   * @param {?string} profile.instagramURL
   * @param {?string} profile.facebookURL
   * @returns {object} Success or Failure Details
  */
  const update = (profile) => {
    // @TODO: Implement this function
  };

  const isAuthenticated = () => {
    if (!jwtToken) { return false; }

    const tokenExpirationOn = new Date(jwt_decode(jwtToken).exp * 1000);

    if (tokenExpirationOn <= new Date()) {
      logout();
      return false;
    }

    return isLoggedIn;
  };

  useEffect(() => {
    const token = localStorage.getItem('jwt-token') || '';

    if (jwtToken === '' && token !== '') {
      setJwtToken(token);
    } else {
      localStorage.setItem('jwt-token', jwtToken);
    }

    if (jwtToken === '') {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [jwtToken]);

  useEffect(() => {
    const profile = localStorage.getItem('user-profile') || '';

    if (isObjectEmpty(currentUser) && profile !== '') {
      setCurrentUser(JSON.parse(profile));
    } else {
      const user = isObjectEmpty(currentUser) ? '' : JSON.stringify(currentUser);
      localStorage.setItem('user-profile', user);
    }
  }, [currentUser]);

  return {
    login,
    logout,
    signup,
    update,
    isAuthenticated,
    currentUser,
    jwtToken,
  };
};

export default useAuth;
