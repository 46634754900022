import React, { useContext } from 'react';
import useAPI from '../../hooks/useAPI';
import JwtTokenContext from '../../contexts/JwtToken';
import { Link } from 'react-router-dom';

const Events = () => {
  const jwtToken = useContext(JwtTokenContext);

  const [{response, loading, error}, request] = useAPI({ // eslint-disable-line no-unused-vars
    path: 'events',
    token: jwtToken,
  });

  return (
    <>
      <h1>Events</h1>

      <h2 className='text-white'>My Events</h2>

      {loading && <h2 className='text-white'>Loading...</h2>}
      {error && <h2 className='text-white'>Something went wrong...</h2>}

      {response && response.data && response.data.length === 0 && <h3 className='text-white'>You haven't created any events yet! You should change that!</h3>}

      {
        response && response.data &&
        <ul>
          {response.data.map(event => (
            <li key={event.id}>
              <Link className='text-white' to={`/events/${event.id}`}>{event.name}</Link>
            </li>
          ))}
        </ul>
      }
    </>
  );
};

export default Events;
