import React from "react";
import Slideshow from '../../components/common/slideshow/Slideshow';
import {
  NavLink, // eslint-disable-line no-unused-vars
} from 'react-router-dom';

// Image Assets
import { ReactComponent as RegistrationImage } from './registration.svg';
import { ReactComponent as LandingPageImage } from './landing-page.svg';
import { ReactComponent as SellTicketsImage } from './sell-tickets.svg';
import { ReactComponent as ContentImage } from './content.svg';
import { ReactComponent as AnalyticsImage } from './analytics.svg';
import { ReactComponent as StageImage } from './stage.svg';
import { ReactComponent as ReactionsImage } from './reactions.svg';
import { ReactComponent as QuestionImage } from './question.svg';
import { ReactComponent as PollImage } from './poll.svg';
import { ReactComponent as MessageImage } from './messages.svg';
import { ReactComponent as LiveChatImage } from './live-chat.svg';
import { ReactComponent as NetworkingImage } from './networking.svg';
import { ReactComponent as TheStageImage } from './the-stage.svg';
import { ReactComponent as DemoImage } from './demo.svg';
import linkedInIcon from '../contact/linkedin-48.png';
import twitterIcon from '../contact/twitter-48.png';
import instagramIcon from '../contact/instagram-48.png';

const eventTypes = ['Conferences', 'Webinars', 'Hackathons', 'Expos', 'Online Courses'];

const Home = () => {
  return (
    <div className=''>
      <div className='container mx-auto text-white mt-10'>
        <h1 className='text-4xl lg:px-40 text-center leading-loose'>
          The <strong className='text-royalpurple font-semibold'>event platform</strong> that empowers organizers to truly create <strong className='text-royalpurple font-semibold'>engaging online</strong> events for <Slideshow className='bg-royalpurple w-64 leading-relaxed px-2 rounded' type='text' delay={3000} inline={true} content={eventTypes} />
        </h1>
        <div className='px-5 lg:px-0 mb-10 lg:mb-0 flex flex-col-reverse lg:flex-row lg:items-center lg:justify-center'>
          <div className='lg:w-1/2 lg:mr-5 text-center lg:text-left'>
            <h2 className='text-4xl lg:text-5xl font-normal lg:pr-40 -mt-32 md:mt-0 mb-8 lg:mb-12'>One-Stop Shop for your live events</h2>
            <p className='text-xl mb-5'>
              <span className='pr-2'>✔</span>Create a landing page, handle registrations and sell tickets
            </p>
            <p className='text-xl mb-5'>
              <span className='pr-2'>✔</span>Showcase your content, speakers, partners, and sponsors
            </p>
            <p className='text-xl mb-5'>
              <span className='pr-2'>✔</span>Engage with your audience and let them connect with others
            </p>
          </div>
          <StageImage className='w-full -mt-16 md:mt-0 lg:w-1/2 lg:ml-5 bg-transparent' alt='Virtual Stage' />
        </div>
      </div>

      <div className='bg-royalpurple py-32 lg:py-64 text-white'>
        <div className='container mx-auto'>
          <h3 className='lg:w-1/2 lg:mx-auto text-center text-2xl leading-relaxed px-4'>
            <span className='text-4xl mr-px'>“</span>
            Whether you have a few dozen or thousands of people joining your event, focus on hosting your audience with peace of mind.
            <span className='text-4xl leading-none'>”</span>
          </h3>
        </div>
      </div>

      <div className='bg-gray-100 py-12'>
        <div className='container mx-auto px-3 lg:px-0'>
          <h2 className='text-4xl font-semibold text-center pb-4'>All-in-one platform for your online events</h2>
          <p className='text-xl mb-12 text-center'>
            Built from the ground up to make event management simple!
          </p>

          <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-12 gap-20'>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <RegistrationImage className='w-full -mt-32 lg:-mt-40 -mb-32 md:-mb-24 lg:-mb-20' alt='Event Registrations' />
              <h2 style={{marginTop: '-5.6rem'}} className='text-3xl font-semibold mb-2'>Registrations</h2>
              <p className='text-l mb-5'>
                Always know who's attending your event and why by asking them any questions you want when they register for your event.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-start-5 lg:col-end-9 text-center'>
              <LandingPageImage className='w-full -mt-32' alt='Event Landing Page' />
              <h2 className='text-3xl font-semibold mb-2 -mt-32 md:-mt-24 lg:-mt-20'>Landing Page</h2>
              <p className='text-l mb-5'>
                Create a simple and beautiful landing page in minutes, showcasing your event agenda, speakers, partners, and sponsors.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 lg:col-start-10 text-center'>
              <SellTicketsImage className='w-full -mt-48 lg:-mt-56 -mb-48 md:-mb-40 lg:-mb-32' alt='Handle Ticket Sales' />
              <h2 style={{marginTop: '-8.5rem'}} className='text-3xl font-semibold mb-2'>Sell Tickets</h2>
              <p className='text-l mb-5'>
                Let your attendees join your event for free or have them buy tickets without you having to handle payments.
              </p>
            </div>

            <div className='col-span-3 md:col-start-2 lg:col-start-3 lg:col-span-3 text-center'>
              <AnalyticsImage className='w-full -mt-24 lg:-mt-32 -mb-32 md:-mb-24 lg:-mb-32' alt='Event Analytics' />
              <h2 className='text-3xl font-semibold mt-4 mb-2'>Analytics</h2>
              <p className='text-l mb-5'>
                Measure and boost the impact your event has by analyzing engagement and retention information, down to a second.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-start-8 lg:col-span-3 text-center lg:-mt-10'>
              <ContentImage className='w-full -mt-64 mb-10 lg:mb-5' alt='Promote Event Content' />
              <h2 style={{marginTop: '-18rem'}} className='text-3xl font-semibold mb-2'>Content</h2>
              <p className='text-l mb-5'>
                Promote your event agenda, speakers, partners, or sponsors for all to see in the event reception, the stage, and the social lounges.
              </p>
            </div>

          </div>
        </div>
      </div>

      <div className='bg-royalpurple py-32 lg:py-64 text-white'>
        <h3 className='container mx-auto text-center text-2xl'>Don't let your <div className='block my-4 lg:my-0 lg:inline-block'><Slideshow className='bg-white text-royalpurple font-bold w-64 leading-relaxed mx-2 px-2 py-2 rounded' type='text' delay={1500} inline={true} content={eventTypes} /></div> feel like a one-way stream.</h3>
      </div>

      <div className='bg-gray-100 py-12'>
        <div className='container mx-auto px-3 lg:px-0'>
          <h2 className='text-4xl font-semibold text-center pb-4'>Engagement is the heart of every event</h2>
          <p className='text-xl mb-12 text-center'>
            Built from the ground up to connect and engage!
          </p>

          <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-12 gap-20'>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <TheStageImage className='w-full -mt-40 lg:-mt-20 -mb-32 lg:mb-0' alt='The Stage' />
              <h2 style={{marginTop: '-2.6rem'}} className='text-3xl font-semibold mb-2'>The Stage</h2>
              <p className='text-l mb-5'>
                Go live and bring other speakers on the stage with you to have a lively conversation or whatever else you have in mind while sharing your cameras, and screen.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-start-5 lg:col-end-9 text-center'>
              <ReactionsImage className='w-full -mt-56' alt='Emoji Reactions' />
              <h2 className='text-3xl font-semibold mb-2 -mt-48'>Reactions</h2>
              <p className='text-l mb-5'>
                Watch your audience react to everything with a storm of emojis.
              </p>
              <ul className='flex flex-row items-center justify-around text-2xl'>
                <li><span role='img' aria-label='Heart'>❤</span></li>
                <li><span role='img' aria-label='Thumbs up'>👍</span></li>
                <li><span role='img' aria-label='Clap'>👏</span></li>
                <li><span role='img' aria-label='LoL'>😆</span></li>
              </ul>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 lg:col-start-10 text-center'>
              <NetworkingImage className='w-full -mt-48 -mb-56 lg:mb-0' alt='Social Lounges & Networking' />
              <h2 style={{marginTop: '-11rem'}} className='text-3xl font-semibold mb-2'>Social Lounges</h2>
              <p className='text-l mb-5'>
                Allow your attendees to make meaningful connections with each other in-between sessions and breaks using lounge rooms with custom names and sponsor branding.
              </p>
            </div>

            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <QuestionImage className='w-full -mt-32' alt='Ask Questions' />
              <h2 style={{marginTop: '-9.6rem'}} className='text-3xl font-semibold mb-2'>Questions</h2>
              <p className='text-l mb-5'>
                Make your audience a part of the event by letting them “Raise Hands” to join you live on stage and ask questions like a physical event.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <PollImage className='w-full -mt-20 mb-10 lg:mb-0' alt='Live Pools' />
              <h2 style={{marginTop: '-7rem'}} className='text-3xl font-semibold mb-2'>Live Polls</h2>
              <p className='text-l mb-5'>
                Keep your audience engaged with quick polls to gather their opinions and feedback.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <LiveChatImage className='w-full -mt-56' alt='Live Chat' />
              <h2 style={{marginTop: '-14.1rem'}} className='text-3xl font-semibold mb-2'>Live Chat</h2>
              <p className='text-l mb-5'>
                Allow your audience to publicly discuss the event, talk in the lobby, or message each other privately.
              </p>
            </div>
            <div className='col-span-3 md:col-start-2 lg:col-span-3 text-center'>
              <MessageImage className='w-full -mt-12' alt='On-Screen Messages' />
              <h2 style={{marginTop: '-4rem'}} className='text-3xl font-semibold mb-2'>Screen Messages</h2>
              <p className='text-l mb-5'>
                Share your call-to-action or any messages you want with your audience using On-Screen Messages.
              </p>
            </div>

          </div>
        </div>
      </div>

      <div className='bg-royalpurple py-32 lg:py-64 text-white'>
        <div className='container mx-auto'>
          <div className='lg:w-1/2 px-5 lg:px-0 lg:mx-auto text-center text-2xl leading-relaxed'>
            All you need is a modern browser!
            <div className='text-xl italic mt-5'>
              <span className='text-2xl'>“</span>
              Nothing to Download
              <span className='text-2xl leading-none'>”</span>
            </div>
          </div>
        </div>
      </div>

      <div className='container mx-auto text-black md:pb-32 lg:pb-0'>
        <div className='flex flex-col md:flex-row items-center justify-center bg-white rounded p-5 pb-48 md:pb-24 md:px-5 md:pt-20 lg:p-10 md:mb-32 lg:my-32'>
          <div className='w-full lg:w-1/2 -mt-40 md:-mt-56 lg:mt-0 -mb-48 md:-mb-64 lg:mb-0 lg:mr-5'>
            <DemoImage className='w-full' alt='Request Demo' />
          </div>
          <div className='lg:w-1/2 md:ml-10 lg:ml-5'>
            <h3 className='text-3xl font-semibold mb-5'>Interested in hosting an event?</h3>
            <div className='text-xl mb-12 lg:mb-20'>
              Request a demo for HeyOZU and start using its all-in-one platform to make your life easier when organizing your <Slideshow className='text-royalpurple font-semibold w-40' type='text' delay={3000} inline={true} content={eventTypes} />
            </div>
            <a className='bg-royalpurple hover:bg-black text-white font-semibold py-4 px-12 rounded focus:outline-none focus:shadow-outline text-xl text-center block md:inline' rel='noopener noreferrer' target='_blank' href="mailto:hello@heyozu.com?subject=Demo%20Request&amp;body=Please%20mention%20your%20details%20along%20with%20your%20use-case%20for%20HeyOZU.">Request Demo</a>
          </div>
        </div>

        <div className='hidden md:mb-64 lg:mb-0 md:-mt-16 md:flex flex-col md:flex-row lg:mt-10 items-center lg:border-t border-gray-800 lg:pt-10 lg:pb-5 md:px-5 lg:px-0'>
          <div className='w-1/2'>
            <p className='text-xl mb-4 flex flex-row justify-start mt-2'>
              <a className='block mr-10' href='https://www.linkedin.com/company/heyozu/' rel='noopener noreferrer' target='_blank'>
                <img src={linkedInIcon} alt="LinkedIn" className="w-10" />
              </a>
              <a className='block mr-10' href='http://www.twitter.com/HeyOZU_Official' rel='noopener noreferrer' target='_blank'>
                <img src={twitterIcon} alt="Twitter" className="w-10" />
              </a>
              <a className='block' href='https://www.instagram.com/HeyOZU/' rel='noopener noreferrer' target='_blank'>
                <img src={instagramIcon} alt="Instagram" className="w-10" />
              </a>
            </p>
          </div>
          <div className='w-1/2 text-right'>
            <a className='text-gray-400 hover:text-gray-200' rel='noopener noreferrer' target='_blank' href="mailto:hello@heyozu.com?subject=Just%20saw%20HeyOZU%20and%20this%20is%20crazy%20but%20I%27ve%20got%20something%20to%20say">hello@heyozu.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
