import React, { useState, useEffect } from 'react';

const Slideshow = (props) => {
  const {
    type,
    _orientation = 'v', // eslint-disable-line no-unused-vars
    delay = 1000,
    inline,
    content,
    className = ''
  } = props;
  const classNames = className.split(' ');

  // State Hooks
  const [slideNumber, setSlideNumber] = useState(0);
  const [slideshowContent, setSlideshowContent] = useState();
  const [timer, setTimer] = useState();

  if (inline) {
    classNames.push('inline-block');
  } else {
    classNames.push('block');
  }

  // Hook to start the timer
  useEffect(() => {
    setTimer(setInterval(() => setSlideNumber(n => n < content.length - 1 ? n + 1 : 0), delay));

    return () => clearInterval(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the UI based on the current slide number
  useEffect(() => {
    if (type === 'text') {
      setSlideshowContent(content.map((item, index) => {
        return <li key={index} className={`inline-block ${index !== slideNumber ? 'hidden' : ''}`}>{item}</li>;
      }));
    }
  }, [slideNumber, type, content]);


  return (
    <ul className={classNames.join(' ')}>
      {slideshowContent}
    </ul>
  );
};

export default Slideshow;
