import { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

const EVENTS = {
  USER: {
    PUBLISHED: 'user-published',
    UNPUBLISHED: 'user-unpublished',
    JOINED: 'user-joined',
    LEFT: 'user-left',
  }
};

const useAgora = (client) => {
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [testVideoTrack, setTestVideoTrack] = useState();

  /**
   * Create local Audio/Camera track based on supplied configuration
   * @param {object} audioConfig
   * @param {object} videoConfig
   * @returns {Array} Microphone and Camera Tracks
  */
  const createLocalTrack = async (audioConfig, videoConfig) => {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);

    // Save the microphone and camera tracks in the state to be used later on.
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);

    return [microphoneTrack, cameraTrack];
  };

  const createTestCameraTrack = async(videoConfig) => {
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);

    setTestVideoTrack(cameraTrack);

    return cameraTrack;
  };

  const destroyTestCameraTrack = () => {
    testVideoTrack.stop();
    testVideoTrack.close();
    setTestVideoTrack(null);
  };

  /*
   * Join a channel
   * @param {string} appid - Unique App ID
   * @param {string} channel - A string that provides a unique channel name for the call.
   * The length must be within 64 bytes.
   * @param {?string} token - Token generated at our server
   * @param {?string} uid - Optional User ID
  */
  const join = async (appid, channel, token = null, uid = null) => {
    // There is nothing to join if the client is not defined
    if (!client) return;

    const [microphoneTrack, cameraTrack] = await createLocalTrack();

    await client.join(appid, channel, token, uid);
    // @TODO: Should not be done for audience members
    await client.setClientRole('host');
    await client.publish([microphoneTrack, cameraTrack]);

    // @TODO: Decide whether to keep this bit or not
    window.client = client;
    window.videoTrack = cameraTrack;

    setJoinState(true);
  };
  const joinAsAudience = async (appid, channel, token = null, uid = null) => {
    // There is nothing to join if the client is not defined
    if (!client) return;

    await client.join(appid, channel, token, uid);

    setJoinState(true);
  };

  /*
   * Leave channel
  */
  const leave = async () => {
    // There is nothing to leave if the client is not defined
    if (!client) return;

    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }

    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }

    setRemoteUsers([]);
    setJoinState(false);

    await client.leave();
  };

  useEffect(() => {
    // Nothing to do if the client is not defined
    if (!client) return;

    setRemoteUsers(client.remoteUsers);

    /*
     * Re-render all remote users when someone joins or leaves
     * @private
    */
    const _setRemoteUsers = () => {
      setRemoteUsers([...client.remoteUsers]);
    };

    /*
     * @param {object} user - IAgoraRTCRemoteUser
     * @param {string} mediaType - Valid values: audio, video
    */
    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);

      _setRemoteUsers();
    };
    const handleUserUnpublished = (user) => { _setRemoteUsers(); };
    const handleUserJoined = (user) =>      { _setRemoteUsers(); };
    const handleUserLeft = (user) =>        { _setRemoteUsers(); };

    client.on(EVENTS.USER.PUBLISHED,    handleUserPublished);
    client.on(EVENTS.USER.UNPUBLISHED,  handleUserUnpublished);
    client.on(EVENTS.USER.JOINED,       handleUserJoined);
    client.on(EVENTS.USER.LEFT,         handleUserLeft);

    return () => {
      client.off(EVENTS.USER.PUBLISHED,   handleUserPublished);
      client.off(EVENTS.USER.UNPUBLISHED, handleUserUnpublished);
      client.off(EVENTS.USER.JOINED,      handleUserJoined);
      client.off(EVENTS.USER.LEFT,        handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    join,
    leave,
    joinState,
    remoteUsers,
    joinAsAudience,
    createTestCameraTrack,
    destroyTestCameraTrack,
    testVideoTrack,
  };
};

export default useAgora;
