import React from 'react';
import {
  NavLink,
  Link
} from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const Header = (props) => {
  const { logout, isAuthenticated, currentUser } = props;

  const LogoutLink = <Link to='#' onClick={logout}>Logout</Link>;

  const loggedOutNavBar = <>
    <li className="flex-initial mx-4 text-white hidden">
      <NavLink to="/why" activeClassName="active">Why HeyOZU</NavLink>
    </li>
    <li className="flex-initial mx-4 text-white hidden">
      <NavLink to="/features" activeClassName="active">Features</NavLink>
    </li>
    <li className="md:flex-initial mx-4 text-white">
      <NavLink className="text-center pt-5 md:p-0 block" to="/contact" activeClassName="active">Contact</NavLink>
    </li>
    <li className="md:flex-initial mx-4 text-white md:border-l border-dotted border-gray-700 md:pl-8 text-center hidden">
      <NavLink className="text-center py-5 md:p-0 block" to="/login" activeClassName="active">Log In</NavLink>
    </li>
    <li className="md:flex-initial mx-4 text-white block hidden">
      <NavLink
        className="bg-royalpurple hover:bg-white hover:text-black py-2 px-4 rounded block text-center"
        to="/signup">
        Sign Up
      </NavLink>
    </li>
    <li className="md:flex-initial mx-4 text-white block md:border-l border-dotted border-gray-700 md:pl-8 mt-5 md:mt-0">
      <a className='bg-royalpurple hover:bg-white hover:text-black py-2 px-4 rounded block text-center' rel='noopener noreferrer' target='_blank' href="mailto:hello@heyozu.com?subject=Demo%20Request&amp;body=Please%20mention%20your%20details%20along%20with%20your%20use-case%20for%20HeyOZU.">Request Demo</a>
    </li>
  </>;

  const loggedInNavBar = isAuthenticated() && <>
    <li className="flex-initial mx-4 text-white">
      Welcome, { currentUser.email }!
    </li>
    <li className="flex-initial mx-4 text-white">
      { LogoutLink }
    </li>
  </>;

  const navBar = isAuthenticated() ? loggedInNavBar : loggedOutNavBar;

  return (
    <header className="bg-black py-4 px-8">
      <nav className="grid grid-cols-12 gap-4">
        <h1 className="col-span-12 md:col-span-2 text-center">
          <NavLink to="/" activeClassName="active" className='block text-center'>
            <img
              src={logo}
              className="md:w-48"
              alt="HeyOZU Logo" />
          </NavLink>
        </h1>
        <ul className="col-span-12 md:col-span-10 md:flex md:justify-end md:items-center font-semibold border-t border-gray-700 md:border-0">
          { navBar }
        </ul>
      </nav>
    </header>
  );
};

export default Header;
