import React from 'react';

/*
 * @param {object} props
 * @param {string} props.value
 * @param {string} props.name
 * @param {string} props.id
 * @param {string} props.type
 * @param {string} props.placeholder
 * @param {boolean} props.error
*/
const Input = (props) => {
  const cssClasses = `shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${props.error ? 'border-red-500' : ''}`;

  return (
    <input
      id={props.id}
      name={props.name}
      className={cssClasses}
      type={props.type}
      placeholder={props.placeholder}
      defaultValue={props.value}
    />
  );
};

export default Input;
