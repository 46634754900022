import { useState, useEffect } from 'react';

const API_URLS = {
  DEVELOPMENT: 'http://localhost:3636',
  STAGING: '<NO_VALUE>',
  PRODUCTION: 'https://api.heyozu.com',
};

// @TODO: Refactor using React Router
const _getHashParams = () => {
  const hash = window.location.hash.substr(1);

  const results = hash.split('&').reduce((result, item) =>  {
    const parts = item.split('=');
    result[parts[0]] = parts[1];
    return result;
  }, {});

  return results;
};

/*
 * Get the API URL for the current environment
 *
 * @returns {string} API URL
*/
const useApiUrl = () => {
  const [apiUrl, setApiUrl] = useState();

  // Set the API URL when the app loads for the first time
  useEffect(() => {
    const hashParams = _getHashParams();

    if (hashParams.env) {
      sessionStorage.setItem('env', hashParams.env);
    }

    const env = sessionStorage.getItem('env');

    if (env === 'stag') { setApiUrl(API_URLS.STAGING); }
    if (env === 'prod') { setApiUrl(API_URLS.PRODUCTION); }

    if (!env) {
      if (window.location.hostname === 'localhost') {
        setApiUrl(API_URLS.DEVELOPMENT);
      } else {
        setApiUrl(API_URLS.PRODUCTION);
      }
    }
  }, []);

  return apiUrl;
};

export default useApiUrl;
