import React, { useContext } from 'react';
import useAPI from '../../hooks/useAPI';
import JwtTokenContext from '../../contexts/JwtToken';
import { useParams, Link } from 'react-router-dom';
import Agenda from './components/Agenda';
import { compiler } from 'markdown-to-jsx';
import Moment from 'react-moment';
import 'moment-timezone';
import Button from '../../components/common/button/Button';
import './Event.css';
import currentUserContext from '../../contexts/currentUser';

const Event = () => {
  const jwtToken = useContext(JwtTokenContext); // eslint-disable-line no-unused-vars
  const currentUser = useContext(currentUserContext);

  const { id } = useParams();

  // @TODO: If the user is logged in, send their JWT Token with the request
  const [{response, loading, error}, request] = useAPI({ // eslint-disable-line no-unused-vars
    path: `events/${id}`
  });

  return (
    <>
      {loading && <h2 className='text-white'>Loading...</h2>}
      {error && <h2 className='text-white'>Something went wrong...</h2>}

      {response && response.data &&
        <>
          {currentUser.id === response.data.user_id &&
          <ul className='flex items-start justify-between mt-20 lg:mx-12 bg-black rounded p-5 text-white'>
            <li className='text-center'>
              <span className='block font-semibold uppercase'>Max Attendees</span>
              <strong className='text-lg'>{response.data.attendee_limit}</strong>
            </li>
            <li className='text-center'>
              <span className='block font-semibold uppercase'>Stage Time Limit</span>
              <strong className='text-lg'>{response.data.stage_minutes_limit / 60} hour(s)</strong>
            </li>
            <li className='text-center'>
              <span className='block font-semibold uppercase'>Max # of Speakers</span>
              <strong className='text-lg'>{response.data.people_on_stage_limit}</strong>
            </li>
            <li className='self-center'>
              <Link to={{
                      pathname: `/events/${id}/stage`,
                      event: response.data
                    }}
                className='bg-royalpurple hover:bg-white text-white hover:text-black font-bold py-4 px-8 text-lg rounded focus:outline-none focus:shadow-outline'>
                GO TO STAGE
              </Link>
            </li>
          </ul>}
          <div className='flex items-start justify-center pt-20'>
            <div className='w-full max-w-xs mr-5'>
              <div className='bg-black rounded'>
                <div className='w-full max-w-xs mr-5 bg-black rounded'>
                  <h1 className='bg-royalpurple rounded-t text-white p-2 text-2xl font-semibold mb-4'>{response.data.name}</h1>
                  <div className='text-white p-4 pt-0 mb-4 event-description'>{compiler(response.data.description)}</div>
                </div>
              </div>
              <div className='rounded bg-black mt-4 flex justify-start'>
                <div className='flex items-center justify-center flex-col bg-white text-black px-4 py-2 rounded-l'>
                  <Moment
                    className='font-semibold uppercase'
                    format='MMM'
                    date={response.data.start_on} />
                  <Moment
                    className='text-xl font-bold'
                    format='DD'
                    date={response.data.start_on} />
                  <Moment
                    className='text-sm'
                    format='YYYY'
                    date={response.data.start_on} />
                </div>
                <Button className='w-full text-xl uppercase hover:bg-white hover:text-black rounded-l-none border-l-2 border-gray-200 bg-green-600'>
                  Register
                </Button>
              </div>
            </div>
            <div className='bg-black rounded-t text-white'>
              <Agenda sessions={response.data.sessions} />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default Event;
