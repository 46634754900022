import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '../../components/common/input/Input';
import Button from '../../components/common/button/Button';

const Signup = (props) => {
  const history = useHistory();

  const [fields, setFields] = useState({
    email: '',
    password: '',
  });

  const onChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const result = await props.signup(fields.email, fields.password);

    if (result[0]) {
      history.push('/');
    } else {
      alert('There seems to be a problem');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-xs">
        <form onChange={onChange} onSubmit={onSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="uppercase text-center font-semibold text-2xl mb-4">Signup</h1>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor='username'>Email</label>
            <Input name='email' value={fields.email} type='email' placeholder='Your email address' id='username' />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password</label>
            <Input name='password' value={fields.password} type='password' placeholder='******************' id='password' />
          </div>
          <div className="flex items-center justify-between">
            <Button type='submit'>
              Register
            </Button>
            <button className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
              Forgot Password?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
