import React from 'react';
import { Formik, Form, useField, useFormikContext, Field } from 'formik'; // eslint-disable-line no-unused-vars
import * as Yup from 'yup';

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={props.id || props.name}>{label}</label>
      <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs italic mt-2'>{meta.error}</div>
      ) : null}
    </>
  );
};

// @TODO: Implementation not complete
const RadioGroup = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={props.id || props.name}>{label}</label>
      <label>
        <Field type='radio' {...field} {...props} />
        Yes
      </label>
      <label>
        <Field type='radio' {...field} {...props} />
        No
      </label>
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs italic mt-2'>{meta.error}</div>
      ) : null}
    </>
  );
};

const Demo = () => {
  return (
    <div className='container mx-auto text-black'>
      <div className='flex flex-col items-center justify-center bg-white rounded p-10 my-32'>
        <h1 className='text-3xl font-semibold mb-5'>Get a Demo</h1>
        <p className='mb-10'>Please fill in the form below to arrange a demo for HeyOZU</p>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            acceptedTerms: false,
            yourRegion: '',
            companyName: '',
            jobTtitle: '',
            eventAgency: false,
            eventTypes: '',
            eventBudget: '',
            eventSize: '',
            comments: ''
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .max(30, 'Must be 30 characters or less')
              .required('Required'),
            lastName: Yup.string()
              .max(30, 'Must be 30 characters or less')
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            acceptedTerms: Yup.boolean()
              .required('Required')
              .oneOf([true], 'You must accept the terms and conditions.'),
            mobileNumber: Yup.string()
              .max(15, 'Must be 15 characters or less'),
          })}
          onSubmit={async (values, { setSubmittng }) => {
            console.log('= FORM SUBMIT');
          }}>
          <Form className='w-full max-w-lg'>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextInput
                  label='First Name'
                  name='firstName'
                  type='text'
                  placeholder='John' />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <TextInput
                  label='Last Name'
                  name='lastName'
                  type='text'
                  placeholder='Doe' />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <TextInput
                  label='Work Email'
                  name='email'
                  type='email'
                  placeholder='example@domain.com' />
              </div>
            </div>

            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextInput
                  label='Company Name'
                  name='companyName'
                  type='text'
                  placeholder='Acme Corporation' />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <TextInput
                  label='Job Title'
                  name='jobTtitle'
                  type='text'
                  placeholder='President' />
              </div>
            </div>

            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextInput
                  label='Mobile'
                  name='mobileNumber'
                  type='text'
                  placeholder='966512345678' />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <RadioGroup
                  label='Are you an events agency?'
                  name='eventAgency' />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Demo;
