import React, {
  useState,
  useContext,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { compiler } from 'markdown-to-jsx'; // eslint-disable-line no-unused-vars
import AgoraRTC from 'agora-rtc-sdk-ng';

// Hooks
import useAgora from '../../hooks/useAgora';
import useAPI from '../../hooks/useAPI';

// Contexts
import JwtTokenContext from '../../contexts/JwtToken';
import currentUserContext from '../../contexts/currentUser';

// Local Components
import MediaPlayer from '../../components/common/mediaplayer/MediaPlayer';
import Button from '../../components/common/button/Button';
import Countdown from '../../components/common/countdown/Countdown';

import './Stage.css';

const STATE = {
  OFFLINE: 'OFFLINE',
  TEST: 'TEST',
  LIVE: 'LIVE',
};

const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

const Stage = (props) => {
  const [appID, setAppID] = useState('');
  const [channel, setChannel] = useState('');
  const [token, setToken] = useState('');
  const [state, setState] = useState();
  const [videoPlayerContent, setVideoPlayerContent] = useState(null);

  const {
    localAudioTrack,
    localVideoTrack,
    join,
    leave,
    joinState,
    remoteUsers,
    joinAsAudience,
    createTestCameraTrack,
    destroyTestCameraTrack,
    testVideoTrack,
  } = useAgora(client);

  const jwtToken = useContext(JwtTokenContext);
  const currentUser = useContext(currentUserContext);

  const { eventID } = useParams();

  const [{response, loading, error}, request] = useAPI({ // eslint-disable-line no-unused-vars
    path: `events/${eventID}`,
    token: jwtToken,
  });

  useEffect(() => {
    if (loading === false && response && response.data) {
      // Agora App ID is used by all
      setAppID(response.data.aaid);

      // Generate channel name for this event and stage
      setChannel(`e_${response.data.id}_main`);

      // Set the Agora Token if we receive it as a host for the event
      response.data.access_token && setToken(response.data.access_token);

      // Set the default state of the stage
      setState(STATE.OFFLINE);
    }
  }, [loading, response]);


  const getStartingInContent = (startOn, endOn) => {
    const targetStartDate = new Date(startOn);
    const targetEndDate = new Date(endOn);
    const now = new Date();

    if (targetStartDate >= now) {
      return <div className='flex items-center justify-center flex-col h-full'>
        <h3 className='text-white font-bold uppercase text-xl text-center mb-5'>Starting In</h3>
        <Countdown targetDate={targetStartDate} />
      </div>;
    } else if (targetStartDate <= now && targetEndDate >= now) {
      return <div className='flex items-center justify-center flex-col h-full'>
        <h3 className='text-white font-bold uppercase text-xl text-center mb-5'>Break!</h3>
      </div>;
    } else if (targetEndDate <= now) {
      return <div className='flex items-center justify-center flex-col h-full'>
        <h3 className='text-white font-bold uppercase text-xl text-center mb-5'>This event has ended!</h3>
      </div>;
    } else {
      return <div className='flex items-center justify-center flex-col h-full'>
        <h3 className='text-white font-bold uppercase text-xl text-center mb-5'>Starting Soon!</h3>
      </div>;
    }
  };

  // Video Player Content
  useEffect(() => {
    if (response && response.data) {
      switch (state) {
        case STATE.OFFLINE:

          setVideoPlayerContent(
            getStartingInContent(response.data.start_on, response.data.end_on)
          );

          if (currentUser.id !== response.data.user_id) {
            joinAsAudience(appID, channel).then(() => setState(STATE.LIVE));
          }
          break;
        case STATE.TEST:
          setVideoPlayerContent(<MediaPlayer videoTrack={testVideoTrack} />);
          break;
        case STATE.LIVE:
          if (currentUser.id === response.data.user_id) {
            setVideoPlayerContent(<MediaPlayer videoTrack={localVideoTrack} audioTrack={localAudioTrack} />);
          }
          break;
        default:
          throw new Error();
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Attendees Logic
  useEffect(() => {
    if (joinState && state === STATE.LIVE && remoteUsers.length > 0) {
      if (response && response.data) {
        if (currentUser.id !== response.data.user_id) {
          setVideoPlayerContent(
            remoteUsers.map(user => <MediaPlayer key={user.id} videoTrack={user.videoTrack} audioTrack={user.audioTrack} />)
          );
        }
      }
    }

    if (joinState && state === STATE.LIVE && remoteUsers.length === 0) {
      if (response && response.data) {
        setVideoPlayerContent(
          getStartingInContent(response.data.start_on, response.data.end_on)
        );
      }
    }
  }, [joinState, remoteUsers]); // eslint-disable-line react-hooks/exhaustive-deps



  // EVENTS
  const toggleCameraTest = async () => {
    if (state === STATE.OFFLINE) {
      await createTestCameraTrack();
      setState(STATE.TEST);
    } else if (state === STATE.TEST) {
      setState(STATE.OFFLINE);
      destroyTestCameraTrack();
    }
  };

  const toggleLive = async () => {
    if (state === STATE.OFFLINE) {
      await join(appID, channel, token, currentUser.id);
      setState(STATE.LIVE);
    }

    if (state === STATE.LIVE) {
      await leave();
      setState(STATE.OFFLINE);
    }
  };

  return (
    <>
      {loading && <h2 className='text-white'>Loading...</h2>}
      {error && <h2 className='text-white'>Something went wrong...</h2>}

      {response && response.data &&
        <>
          {currentUser.id === response.data.user_id &&
          <ul className='flex items-start justify-between mt-5 lg:mx-12 bg-black rounded p-5 text-white'>
            <li className='self-center'>
              {state !== STATE.LIVE && <Button
                onClick={toggleCameraTest}
                className='bg-gray-200 hover:bg-gray-400 uppercase text-gray-900 font-bold py-4 px-8 text-lg rounded focus:outline-none focus:shadow-outline'>
                {state === STATE.OFFLINE && 'Test Camera'}
                {state === STATE.TEST && 'Stop Camera Test'}
              </Button>}
            </li>
            <li className='text-center'>
              <span className='block font-semibold uppercase'>Stage Time Limit</span>
              <strong className='text-lg'>{response.data.stage_minutes_limit / 60} hour(s)</strong>
            </li>
            <li className='text-center'>
              <span className='block font-semibold uppercase'>Max # of Speakers</span>
              <strong className='text-lg'>{response.data.people_on_stage_limit}</strong>
            </li>
            <li className='self-center'>
              {state !== STATE.LIVE && <Button
                onClick={toggleLive}
                disabled={state === STATE.TEST}
                className='bg-red-600 hover:bg-green-600 text-white font-bold py-4 px-8 text-lg rounded focus:outline-none focus:shadow-outline'>
                GO LIVE
              </Button>}
              {state === STATE.LIVE && <Button
                onClick={toggleLive}
                className='bg-gray-600 hover:bg-red-600 text-black font-bold py-4 px-8 text-lg rounded focus:outline-none focus:shadow-outline'>
                STOP
              </Button>}
            </li>
          </ul>}

          <div className='flex items-start justify-between mt-5'>
            <div className='video w-2/3 mr-10'>

              <div className='video-player-container bg-gray-800 text-white'>
                {videoPlayerContent}
              </div>

              <h1 className='text-white text-xl font-bold'>{response.data.name}</h1>
            </div>
            <div className='chat bg-gray-800 p-10 w-1/3'>
            </div>
          </div>
        </>}
    </>
  );
};

export default Stage;
