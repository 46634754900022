import { useState, useEffect, useReducer, useContext } from 'react';
import axios from 'axios';

import ApiUrlContext from '../contexts/apiurl';

const ACTIONS = {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

const DEFAULT_REQUEST = {
  path: null,
  type: REQUEST_TYPES.GET,
  token: null,
  response: null,
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_INIT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ACTIONS.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        response: action.payload,
      };
    case ACTIONS.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        response: action.payload,
      };
    default:
      throw new Error();
  }
};

/*
 * @param {object} initialRequest
 * @param {string} initialRequest.path
 * @param {string} initialRequest.type
 * @param {string} initialRequest.token
 * @param {object} initialRequest.response
 * @param {object} initialData
 * @returns {}
*/
const useAPI = (initialRequest = DEFAULT_REQUEST) => {
  const baseUrl = useContext(ApiUrlContext);

  const [request, setRequest] = useState({
    url: `${baseUrl}/${initialRequest.path}` || DEFAULT_REQUEST.path,
    type: initialRequest.type || DEFAULT_REQUEST.type,
    token: initialRequest.token || DEFAULT_REQUEST.token,
    data: initialRequest.data || DEFAULT_REQUEST.token,
  });

  const [state, dispatch] = useReducer(fetchReducer, {
    loading: false,
    error: false,
    response: null,
  });

  useEffect(() => {
    console.log('# MAKING AN API CALL', request.url)

    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: ACTIONS.FETCH_INIT });

      try {
        const response = await axios({
          method: request.type,
          url: request.url,
          headers: {
            'Authorization': request.token,
          },
          data: request.data,
        });

        if (!didCancel) {
          dispatch({
            type: ACTIONS.FETCH_SUCCESS,
            payload: response
          });
        }
      } catch (e) {
        if (!didCancel) {
          console.error('useAPI Hook:', e);

          dispatch({
            type: ACTIONS.FETCH_FAILURE,
            payload: e
          });
        }
      }
    };

    fetchData();

    return () => { didCancel = true; };

  }, [request]);

  return [state, setRequest];
};

export default useAPI;
