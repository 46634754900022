import React from 'react';
import Moment from 'react-moment';

const AgendaItem = (props) => {
  const { name, description, start_on } = props.session;

  return (
    <li className='flex justify-between px-4 py-6 '>
      <Moment className='w-1/3' date={start_on} format='HH:mm A' />
      <strong className='w-1/3'>{ name }</strong>
      <p className='w-1/3'>{ description }</p>
    </li>
  );
};

export default AgendaItem;
