import React from 'react';
import AgendaItem from './AgendaItem';

const Agenda = (props) => {
  const { sessions } = props;

  return (
    <>
      <h2 className='text-black text-xl font-semibold bg-white p-2 rounded-t text-center uppercase'>Agenda</h2>
    <ul>
      {sessions.map(session => session.name !== 'Main Stage' && <AgendaItem key={session.id} session={session} />)}
    </ul>
    </>
  );
};

export default Agenda;
