import React from 'react';
import { ReactComponent as ContactUsImage } from './contact-us.svg';
import linkedInIcon from './linkedin-48.png';
import twitterIcon from './twitter-48.png';
import instagramIcon from './instagram-48.png';

const Contact = () => {
  return (
    <div className='flex flex-col lg:flex-row items-start justify-center items-stretch'>
      <ContactUsImage className='hidden lg:block w-full lg:w-1/2 mr-8 ml-2' alt='Content Not Found' />
      <div className='lg:w-1/2 text-white ml-8 mr-2 flex flex-col justify-center items-stretch lowercase mt-10 lg:mt-0'>
        <h1 className='text-4xl font-semibold mb-6'>wanna talk?</h1>
        <p className='text-xl mb-2'>
          Don't be shy, say what you want!
        </p>
        <p className='text-xl mb-2'>
          And if you got nothing, then just say hi over at <a className='bg-royalpurple p-1' rel='noopener noreferrer' target='_blank' href="mailto:hello@heyozu.com?subject=hey%20i%20just%20saw%20HeyOZU%20and%20this%20is%20crazy%20but%20i've%20got%20something%20to%20say&amp;body=Do%20you%20want%20me%20to%20do%20all%20the%20work%20for%20you%20now%3F%20Come%20on%2C%20don't%20be%20that%20lazy!">hello@heyozu.com</a>!
        </p>
        <div className='border border-royalpurple border-opacity-25 my-8'></div>
        <p className='text-xl mb-2'>
          Though, you could also follow us on social media if you want!
        </p>
        <p className='text-xl mb-4 flex flex-row justify-start mt-2'>
          <a className='block mr-10' href='https://www.linkedin.com/company/heyozu/' rel='noopener noreferrer' target='_blank'>
            <img src={linkedInIcon} alt="LinkedIn" className="w-10" />
          </a>
          <a className='block mr-10' href='http://www.twitter.com/HeyOZU_Official' rel='noopener noreferrer' target='_blank'>
            <img src={twitterIcon} alt="Twitter" className="w-10" />
          </a>
          <a className='block' href='https://www.instagram.com/HeyOZU/' rel='noopener noreferrer' target='_blank'>
            <img src={instagramIcon} alt="Instagram" className="w-10" />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
