import React from 'react';

/*
 * @param {object} props
 * @param {object} props.children
 * @param {?string} props.type
*/
const Button = (props) => {
  const cssClasses = `bg-royalpurple hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${props.className} ${props.disabled && 'opacity-50 cursor-not-allowed'}`;

  return (
    <button
        onClick={props.onClick}
        className={cssClasses}
        type={props.type ? props.type : ''}>
      {props.children}
    </button>
  );
};

export default Button;
